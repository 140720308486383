<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $tc("forms.title", 0) }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn color="buttons" :loading="loading" @click="addItem">
            <v-icon left> fas fa-plus </v-icon>
            {{ $tc("forms.add") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search :loading="loading" />
        </v-col> -->

        <v-col cols="12" sm="12" md="12">
          <items-table
            @menuClick="handleMenuActions($event)"
            :items="items"
            :showReport="true"
            :showView="true"
            :showEdit="true"
            :showDelete="true"
            :loading="loading"
            :headers="headers"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="addDialog"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
      v-model="addDialog"
    >
      <add-form
        :selectedItem="selectedItem"
        @cancel="addDialog = false"
        :surveyId="selectedItem ? selectedItem['.key'] : null"
        @success="handleSuccess"
      />
    </v-dialog>

    <v-dialog
      v-if="reportsDialog"
      persistent
      max-width="90%"
      v-model="reportsDialog"
    >
      <report
        :selectedItem="selectedItem"
        @cancel="reportsDialog = false"
        @success="handleSuccess"
      />
    </v-dialog>

    <v-dialog
      v-if="viewDialog"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
      v-model="viewDialog"
    >
      <view-item
        :selectedItem="selectedItem"
        :surveyId="selectedItem['.key']"
        @cancel="viewDialog = false"
        @success="handleSuccess"
      />
    </v-dialog>

    <v-dialog max-width="500px" v-if="deleteDialog" v-model="deleteDialog">
      <delete-item
        ref="deleteComponent"
        :name="selectedItem.name"
        @success="confirmDelete"
        @cancel="deleteDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import addForm from "./add.vue";
import viewItem from "./preview";
import deleteItem from "@/components/delete";
import itemsTable from "@/components/files-table";
import report from "./reports";

export default {
  name: "Forms",
  components: {
    itemsTable,
    addForm,
    deleteItem,
    viewItem,
    report,
  },
  data() {
    return {
      items: [],
      snackbarText: "",
      snackbar: false,
      loading: true,
      addDialog: false,
      selectedItem: null,
      deleteDialog: false,
      viewDialog: false,
      reportsDialog: false,
      headers: [
        {
          text: this.$tc("headers.name"),
          value: "name",
          width: "150px",
        },
        {
          text: this.$tc("headers.instructions"),
          value: "description",
          width: "200px",
        },
        {
          text: this.$tc("headers.number_of_questions"),
          value: "numberOfQuestions",
          width: "200px",
        },
        {
          text: this.$tc("headers.times_answered"),
          value: "timesAnswered",
          width: "200px",
        },

        {
          text: this.$tc("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          width: "200px",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  computed: {
    ...mapState(["groupId", "user"]),
  },
  methods: {
    ...mapActions(["cleanSearch"]),

    addItem() {
      this.selectedItem = null;
      this.addDialog = true;
    },

    editForm(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },
    deleteForm(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    showForm(item) {
      this.selectedItem = item;
      this.viewDialog = true;
    },

    handleSuccess() {
      this.addDialog = false;
      this.snackbarText = this.selectedItem
        ? this.$t("forms.edited")
        : this.$t("forms.added");
      this.snackbar = true;
    },

    handleMenuActions(payload) {
      switch (payload.action) {
        case "reports":
          this.selectedItem = payload.item;
          this.reportsDialog = true;
          break;
        case "show_image":
          this.showForm(payload.item);
          break;
        case "edit":
          this.editForm(payload.item);
          break;
        case "delete":
          this.deleteForm(payload.item);
          break;
      }
    },

    async confirmDelete() {
      this.$refs.deleteComponent.loading = true;

      db.collection("forms")
        .doc(this.selectedItem[".key"])
        .update({
          deleted: true,
          deletedAt: new Date(),
          deletedBy: this.user.uid,
        })
        .then(async (response) => {
          this.snackbarText = this.$tc("forms.deleted");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },
  },
  mounted() {
    this.cleanSearch();

    db.collection("forms")
      .where("groupId", "==", this.groupId)
      .where("deleted", "==", false)
      .onSnapshot((response) => {
        this.items = [];
        response.forEach((item) => {
          let form = item.data();
          form[".key"] = item.id;
          this.items.push(form);
        });
        this.loading = false;
      });
  },
};
</script>

<style>
.v-dialog--fullscreen {
  border-radius: 0 !important;
  background-color: #000000e3;
}
</style>