<template>
  <div>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card :loading="loading" class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">Reportes</span>
        <v-btn
          style="right: 10px; top: 10px; border-radius: 30px !important"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
          v-if="!loading"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <!-- <v-col cols="12" sm="7" md="7">
              <h2 style="color: #00d2ea" class="font-weight-bold mt-3">
                Contestada {{ answers.size }}
                {{ answers.size == 1 ? "vez" : "veces" }}
              </h2>
            </v-col> -->

            <v-col cols="12" sm="12" md="12">
              <download-excel
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                "
                :data="excelData"
                :name="name"
              >
                <img
                  style="width: 50px; cursor: pointer"
                  :src="require('@/assets/excel.png')"
                />
                <span class="font-weight-bold" style="cursor: pointer"
                  >Descargar .xlsx</span
                >
              </download-excel>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="headers"
                :items="allAnswers"
                :items-per-page="5"
                sort-desc
                :sort-by="['answeredAt']"
              >
                <template v-slot:[`item.answeredAt`]="{ item }">
                  {{ item.answeredAt | formatDate }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0 mt-8">
            <v-col cols="12" sm="12" md="12">
              <v-row justify="end">
                <v-btn
                  :loading="loading"
                  @click="$emit('cancel')"
                  class="save-btn"
                  color="buttons"
                  >{{ $t("button.close") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import downloadExcel from "vue-json-excel";

export default {
  name: "add-agency",
  props: ["selectedItem"],
  components: {
    downloadExcel,
  },
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      loading: false,
      answers: [],
      questions: [],
      allAnswers: [],
      answered: 0,
      excelData: [],
    };
  },
  filters: {
    formatDate(timestamp) {
      if (!timestamp) return "";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("DD/MM/YYYY, h:mm a");
    },
  },
  computed: {
    ...mapState(["user", "groupId"]),

    sortedArray: function (array) {
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }
      return array.sort(compare);
    },
    name() {
      return `${moment().format("DD/MM/YYYY")}_RESULTADOS_${
        this.selectedItem.name
      }`;
    },
    headers() {
      let date = {
        text: "Fecha",
        value: "answeredAt",
        width: "250px",
      };

      let questionsHeaders = [];

      if (this.questions.docs) {
        questionsHeaders = this.questions.docs.map((q) => {
          return {
            text: q.data().description,
            value: q.id,
          };
        });
      }

      return [...questionsHeaders, date];
    },
  },

  async mounted() {
    this.loading = true;

    this.answers = await db
      .collection("surveyResponses")
      .where("surveyId", "==", this.selectedItem[".key"])
      .where("groupId", "==", this.groupId)
      .get();

    this.questions = await db
      .collection("forms")
      .doc(this.selectedItem[".key"])
      .collection("questions")
      .where("deleted", "==", false)
      .orderBy("position", "asc")
      .get();

    let answersText = {};
    this.questions.forEach((item) => {
      item.data().answers.forEach((ans) => {
        answersText[ans.id] = ans.text;
      });
    });

    for (let j = 0; j < this.answers.docs.length; j++) {
      let answeredAt = this.answers.docs[j].data().answeredAt;

      let data = {
        answeredAt,
      };

      let excelItem = {
        Fecha: moment(this.answers.docs[j].data().answeredAt.toDate())
          .tz("America/Tegucigalpa")
          .format("DD/MM/YYYY"),
        Hora: moment(this.answers.docs[j].data().answeredAt.toDate())
          .tz("America/Tegucigalpa")
          .format("H:mm:ss"),
      };

      let responses = this.answers.docs[j].data().responses;

      responses.forEach((item) => {
        let questionsResult = this.questions.docs.find(
          (q) => q.id == item.questionId
        );
        let questionName = questionsResult.data()
          ? questionsResult.data().description
          : "";

        excelItem[questionName] = answersText[item.response]
          ? answersText[item.response]
          : "";

        data[item.questionId] = answersText[item.response]
          ? answersText[item.response]
          : "";
      });

      this.allAnswers.push(data);
      this.excelData.push(excelItem);
    }

    this.loading = false;
  },
};
</script>